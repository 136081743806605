(function() {
    var scrollDelta = 150;

    window.addEventListener('scroll', function (evt) {
        if (window.scrollY > scrollDelta && !document.body.classList.contains('thin-header')) {
            document.body.classList.add('thin-header');
            return;
        }
        if (window.scrollY <= scrollDelta) {
            document.body.classList.remove('thin-header');
        }
    })

})(window);
